<template>
  <div class="wrapper">
    <van-address-edit
      :area-list="areaList"
      show-postal
      :show-delete="AddressInfo.id?true:false"
      show-set-default
      show-search-result
      :address-info="AddressInfo"
      :search-result="searchResult"
      :area-columns-placeholder="['请选择', '请选择', '请选择']"
      @save="onSave"
      @delete="onDelete"
    />
  </div>
</template>

<script>
import { areaList } from '@vant/area-data';
import { addressSave, addressRemove } from '../../../../api'
export default {
  components: {},
  props: {},
  data() {
    return {
      areaList,
      searchResult: [],
      AddressInfo: {
        
      }
    };
  },
  watch: {},
  computed: {},
  methods: {
    onSave(e) {
        var data = {
          id: e.id||null,
          detailAddress: e.addressDetail,
          nationalCode: e.areaCode,
          city: e.city,
          region: e.county,
          defaultStatus: e.isDefault?1:0,
          name: e.name,
          postCode: e.postalCode,
          province: e.province,
          phone: e.tel
        }
        this.addressSave(data)
    },
    onDelete(e) {
      this.addressRemove(e.id)
    },
    //新增编辑
    addressSave(data) {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
      })
      data.phone = data.phone.replace(/\s*/g,"")
      addressSave(data).then(res=>{
          this.$toast.success({
            message: data.id?'修改成功':'添加成功',
            duration: 1500,
            forbidClick: true,
          })
          setTimeout(()=>{
            this.$router.go(-1)
          }, 1500)
      }).catch((res)=>{
        this.$toast.clear()
      })
    },
    //删除
    addressRemove(id) {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
      })
      addressRemove({
        id: id
      }).then(res=>{
        this.$toast.success({
            message: '删除成功',
            duration: 1500,
            forbidClick: true,
          })
          setTimeout(()=>{
            this.$router.go(-1)
          }, 1500)
      }).catch((res)=>{
        this.$toast.clear()
      })
    }
  },
  created() {
    if (this.$route.query.id) {
      var obj = this.$route.query
      obj.isDefault = obj.isDefault=='true'?true:false
      obj.postalCode = obj.postCode
      obj.areaCode = obj.nationalCode
      obj.addressDetail = obj.detailAddress
      this.AddressInfo = this.$route.query
    }
    if (this.$route.query.Length==0) {
      this.AddressInfo.isDefault = true
    }
  },
  mounted() {},
};
</script>
<style scoped>
.wrapper {
  width: 100%;
  min-height: 100vh;
  background-color: RGBA(247, 247, 247, 1);
}
.wrapper>>>.van-switch--on{
    background-color: #ee0a24;
}
</style>